import mixpanel from "mixpanel-browser";
import ReactGA from "react-ga4";
import storageService from "./services/storageServices";

mixpanel.init("e904ad96af58aa3fcd4096e098d69fa8");
ReactGA.initialize("G-1XC6Q6RMGL");

const userId = storageService.get("userId");

if (
  typeof window !== "undefined" &&
  typeof window.plotline === "function" &&
  userId
) {
  window.plotline(
    "init",
    "MjZkYTVmYjEtMGJjZi00ZTE5LTk3NWQtNjA2NzRjMDE0NTJh",
    userId
  );
}
// if (Window && userId) {
//   window.plotline(
//     "init",
//     "MjZkYTVmYjEtMGJjZi00ZTE5LTk3NWQtNjA2NzRjMDE0NTJh",
//     userId
//   );
// }

const eventTrack = (category, action, label) => {
  ReactGA.event({ category, action, label });
};

export const trackEvent = (name, data = {}) => {
  let hotelIds = storageService.get("hotel_ids") || {};
  if (!userId) {
    return;
  }

  let obj = {
    customData: data,
    ...userId,
    url: window.location.host,
  };

  const host = window.location.host;
  //   if (host.includes("localhost")) {
  //     return;
  //   }
  mixpanel.track(name, obj);
  //   window !== undefined && window.plotline("track", `${name}_web`, obj);
  if (typeof window.plotline === "function") {
    window.plotline("track", `${name}_web`, obj);
  }
};

const pageView = (page) => {
  ReactGA.send({ hitType: "pageview", page: page });
};

export default { pageView, eventTrack };
