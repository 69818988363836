import axios from "axios";
// import { routes } from "../component_route/Index";
// import { history } from "../history";
// import { history } from "./history";
import storageService from "./storageServices";

console.log("axiosInstance", process.env);

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((request) => {
  const token = storageService.get("token");
  // const token = storageService.get()?.token;
  request.headers.Authorization = `Bearer ${token}`;
  return request;
});

axiosInstance.interceptors.response.use(null, (error) => {
  const { status } = error?.response;
  if (status === 401) {
    storageService.remove();
    // history.push(routes.login);
  }

  return Promise.reject(error);
});

const tryCatchWrapper = async (axiosObject) => {
  try {
    const { data } = await axiosInstance(axiosObject);
    return data;
  } catch (error) {
    return error?.response?.data || {};
  }
};

const httpService = {
  get: (url, params) => tryCatchWrapper({ method: "GET", url, params }),
  post: (url, body) => tryCatchWrapper({ method: "POST", url, data: body }),
  put: (url, body) => tryCatchWrapper({ method: "PUT", url, data: body }),
  patch: (url, body) => tryCatchWrapper({ method: "PATCH", url, data: body }),
  delete: (url, body) => tryCatchWrapper({ method: "DELETE", url, data: body }),
};

export default httpService;
