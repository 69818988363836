import React, { useEffect, useState } from "react";
import classNames from "classnames";

// import getParents from '../../../utilities/getParents';
import slideUp from "../../../utilities/slideUp";
import slideDown from "../../../utilities/slideDown";
import getParents from "../../../utilities/getParents";

import { NavLink, Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import storageService from "../../../services/storageServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function MenuHeading({ className, text, ...props }) {
  const compClass = classNames({
    "nk-menu-heading": true,
    [className]: className,
  });
  return (
    <li className={compClass}>
      <h6 className="overline-title">{text || props.children}</h6>
    </li>
  );
}

function MenuItemTemplate({ text, icon }) {
  return (
    <>
      {icon && (
        <span className="nk-menu-icon">
          <em className={`icon ni ni-${icon}`}></em>
        </span>
      )}
      {text && <span className="nk-menu-text">{text}</span>}
    </>
  );
}

function MenuItemLink({ text, icon, sub, to, blank, onClick }) {
  return (
    <>
      {!blank && !sub && (
        <NavLink className="nk-menu-link" to={to} onClick={onClick}>
          <MenuItemTemplate icon={icon} text={text} />
        </NavLink>
      )}
      {blank && (
        <Link className="nk-menu-link" to={to} target="_blank">
          <MenuItemTemplate icon={icon} text={text} />
        </Link>
      )}
      {sub && (
        <a
          className="nk-menu-link nk-menu-toggle"
          onClick={onClick}
          href="#expand"
        >
          <MenuItemTemplate icon={icon} text={text} />
        </a>
      )}
    </>
  );
}

function MenuItem({ sub, className, ...props }) {
  const compClass = classNames({
    "nk-menu-item": true,
    "has-sub": sub,
    [className]: className,
  });
  return <li className={compClass}>{props.children}</li>;
}

function MenuSub({ mega, className, ...props }) {
  const compClass = classNames({
    "nk-menu-sub": true,
    [className]: className,
  });
  return <ul className={compClass}>{props.children}</ul>;
}

function MenuList({ className, ...props }) {
  const compClass = classNames({
    "nk-menu": true,
    [className]: className,
  });
  return <ul className={compClass}>{props.children}</ul>;
}

function Menu() {
  // variables for Sidebar
  let menu = {
    classes: {
      main: "nk-menu",
      item: "nk-menu-item",
      link: "nk-menu-link",
      toggle: "nk-menu-toggle",
      sub: "nk-menu-sub",
      subparent: "has-sub",
      active: "active",
      current: "current-page",
    },
  };

  let currentLink = function (selector) {
    let elm = document.querySelectorAll(selector);
    elm.forEach(function (item) {
      var activeRouterLink = item.classList.contains("active");
      if (activeRouterLink) {
        let parents = getParents(
          item,
          `.${menu.classes.main}`,
          menu.classes.item
        );
        parents.forEach((parentElemets) => {
          parentElemets.classList.add(
            menu.classes.active,
            menu.classes.current
          );
          let subItem = parentElemets.querySelector(`.${menu.classes.sub}`);
          subItem !== null && (subItem.style.display = "block");
        });
      } else {
        item.parentElement.classList.remove(
          menu.classes.active,
          menu.classes.current
        );
      }
    });
  };

  // dropdown toggle
  let dropdownToggle = function (elm) {
    let parent = elm.parentElement;
    let nextelm = elm.nextElementSibling;
    let speed =
      nextelm.children.length > 5 ? 400 + nextelm.children.length * 10 : 400;
    if (!parent.classList.contains(menu.classes.active)) {
      parent.classList.add(menu.classes.active);
      slideDown(nextelm, speed);
    } else {
      parent.classList.remove(menu.classes.active);
      slideUp(nextelm, speed);
    }
  };

  // dropdown close siblings
  let closeSiblings = function (elm) {
    let parent = elm.parentElement;
    let siblings = parent.parentElement.children;
    Array.from(siblings).forEach((item) => {
      if (item !== parent) {
        item.classList.remove(menu.classes.active);
        if (item.classList.contains(menu.classes.subparent)) {
          let subitem = item.querySelectorAll(`.${menu.classes.sub}`);
          subitem.forEach((child) => {
            child.parentElement.classList.remove(menu.classes.active);
            slideUp(child, 400);
          });
        }
      }
    });
  };

  let menuToggle = function (e) {
    e.preventDefault();
    let item = e.target.closest(`.${menu.classes.toggle}`);
    dropdownToggle(item);
    closeSiblings(item);
  };

  useEffect(() => {
    currentLink(`.${menu.classes.link}`);
    // eslint-disable-next-line
  }, [null]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    console.log("this is called??here?");
    setShowModal(true);
  };
  const handleConfirm = () => {
    storageService.remove("token");
    storageService.remove("hotelId");
    console.log("User logged out, token removed");
    toast.success("user loggedout successfully");
    navigate("/auths/auth-login");
  };

  return (
    <MenuList>
      {/* <MenuItem>
        <MenuItemLink icon="home" text="Home" to="/icons" />
      </MenuItem>
      <MenuItem>
        <MenuItemLink icon="dashboard" text="Analytics" to="/icons" />
      </MenuItem> */}

      <MenuItem>
        <MenuItemLink icon="inbox" text="Inbox" to="/apps/chats" />
      </MenuItem>

      {/* <MenuItem sub>
        <MenuItemLink icon="inbox" text="Inbox" onClick={menuToggle} sub />
        <MenuSub>
          <MenuItem>
            <MenuItemLink text="Bot" to="/apps/chats" />
          </MenuItem>
          <MenuItem>
            <MenuItemLink text="Chat" to="/apps/chats" />
          </MenuItem>
          <MenuItem>
            <MenuItemLink text="Closed" to="/apps/chats" />
          </MenuItem>
        </MenuSub>
      </MenuItem> */}

      {/* <MenuHeading text="Manage" /> */}

      {/* <MenuItem>
        <MenuItemLink icon="db" text="Training data" to="/wip" />
      </MenuItem> */}
      {/* <MenuItem>
       
        <MenuItemLink icon="table-view" text="Instructions" to="/data-table" />
      </MenuItem> */}
      {/* <MenuItem>
       
        <MenuItemLink icon="users" text="Customers" to="/sweetalert" />
      </MenuItem> */}
      {/* <MenuItem>
        
        <MenuItemLink icon="layers" text="Integrations" to="/wip" />
      </MenuItem> */}
      {/* <MenuItem>
      
        <MenuItemLink icon="setting-alt" text="Settings" to="/wip" />
      </MenuItem> */}

      <MenuItem sub>
        <MenuItemLink icon="call" text="Support" onClick={menuToggle} sub />
        <MenuSub>
          {/* <MenuItem>
            <MenuItemLink text="Call" to="tel:+917022686135" />
          </MenuItem> */}
          <MenuItem>
            <MenuItemLink text="7022686135" />
          </MenuItem>
        </MenuSub>
      </MenuItem>

      {/* <MenuItem>
        <MenuItemLink icon="play-circle" text="Test your bot" to="/wip" />
      </MenuItem> */}

      <MenuItem>
        <MenuItemLink icon="signin" text="Logout" onClick={handleLogout} />
      </MenuItem>

      {/* <MenuHeading text="misc pages" />
      <MenuItem sub>
        <MenuItemLink
          icon="signin"
          text="Auth Pages"
          onClick={menuToggle}
          sub
        />
        <MenuSub>
          <MenuItem>
            <MenuItemLink text="Auth Register" to="/auths/auth-register" />
            <MenuItemLink text="Auth Login" to="/auths/auth-login" />
            <MenuItemLink text="Forgot Password" to="/auths/auth-reset" />
          </MenuItem>
        </MenuSub>
      </MenuItem>
      <MenuItem>
        <MenuItemLink icon="files" text="Page 404" to="/not-found" />
      </MenuItem> */}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>LOGOUT</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleConfirm}>
            Yes
          </Button>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </MenuList>
  );
}

export default Menu;
