import React from "react";
import classNames from "classnames";

function Icon({ name, size, className, variant, style, ...props }) {
  const compClass = classNames({
    [`${className}`]: className,
    [`icon ni ni-${name}`]: true,
    [`icon-${size}`]: size,
    [`text-${variant}`]: variant,
  });

  return <em className={compClass} style={style}></em>;
}

export default Icon;
