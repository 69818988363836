import { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import {
  Dropdown,
  Button,
  Offcanvas,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import SimpleBar from "simplebar-react";

import {
  Logo,
  Image,
  Icon,
  MediaAction,
  MediaGroup,
  MediaText,
  Media,
  LinkList,
  LinkListItem,
  CustomDropdownToggle,
  CustomDropdownMenu,
  Schedule,
} from "../../../components";

import Menu from "./Menu";

import ToggleSidebar from "../Toggle/Sidebar";
import ToggleNavbar from "../Toggle/Navbar";

import { useLayout, useLayoutUpdate } from "./../LayoutProvider";
import {
  getConversationsApi,
  getEnabledHotelsApi,
} from "../../../services/apiServices";
import storageService from "../../../services/storageServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useHotelContext } from "../../../App";

function QuickNav({ className, ...props }) {
  const compClass = classNames({
    "nk-quick-nav": true,
    [className]: className,
  });
  return <ul className={compClass}>{props.children}</ul>;
}

function QuickNavItem({ className, ...props }) {
  const compClass = classNames({
    "d-inline-flex": true,
    [className]: className,
  });
  return <li className={compClass}>{props.children}</li>;
}

function Header() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const layout = useLayout();
  const layoutUpdate = useLayoutUpdate();

  const compClass = classNames({
    "nk-header nk-header-fixed": true,
    [`is-${layout.headerVariant}`]: layout.headerVariant,
  });

  const navClass = classNames({
    "nk-header-menu nk-navbar": true,
    "navbar-active": layout.headerActive,
    // eslint-disable-next-line
    "navbar-mobile":
      layout.headerTransition ||
      eval(`layout.breaks.${layout.headerCollapse}`) > window.innerWidth,
  });

  // offcanvas
  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const handleOffcanvasShow = () => setShowOffcanvas(true);
  const [showModal, setShowModal] = useState(false);
  const {
    hotels,
    selectedHotel,
    setSelectedHotel,
    setSelectedChat,
    selectedChat,
  } = useHotelContext();
  const navigate = useNavigate();

  // useEffect(() => {
  //   const storedHotelId = storageService.get("hotelId");

  //   if (storedHotelId) {
  //     setSelectedHotel(storedHotelId);
  //     fetchConversations(storedHotelId);
  //   } else if (hotels.length > 0) {
  //     setSelectedHotel(hotels[0].id);
  //     fetchConversations(hotels[0].id);
  //   }
  // }, [hotels]);

  const handleHotelChange = async (event) => {
    const selectedValue = event.target.value;
    setSelectedHotel(selectedValue);
    setSelectedChat(null);
    storageService.set("hotelId", selectedValue);
    await fetchConversations(selectedValue);
  };
  const handleLogout = () => {
    console.log("here?");
    setShowModal(true);
    // storageService.remove("token");
    // storageService.remove("hotelId");
    // console.log("User logged out, token removed");
    // toast.success("user loggedout successfully");
    // navigate("/auths/auth-login");
  };

  const handleConfirm = () => {
    storageService.remove("token");
    storageService.remove("hotelId");
    console.log("User logged out, token removed");
    toast.success("user loggedout successfully");
    navigate("/auths/auth-login");
  };

  const fetchConversations = async (hotelId) => {
    const chain_ids = storageService.get("chain_ids");
    try {
      const response = await getConversationsApi(hotelId, chain_ids);
      console.log("iin index 136");
    } catch (error) {
      toast.error("Failed to fetch conversations. Please try again later.");
    }
  };

  return (
    <>
      <div className={compClass}>
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-header-logo">
              <ToggleSidebar variant="zoom" icon="menu" />
              {/* <ToggleNavbar className="me-2" /> */}
            </div>
            <h2 className="title" style={{ marginTop: "0.5rem" }}>
              Chats
            </h2>
            {layout.headerActive && (
              <div
                className="navbar-overlay"
                onClick={layoutUpdate.headerMobile}
              ></div>
            )}
            <nav className={navClass}>
              <Menu />
            </nav>
            <div
              className="nk-header-tools"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* <QuickNav
                className="flex flex-col justify-between items-center w-[100%]"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "sace-between",
                }}
              > */}
              {/* select hotels */}
              {/* <div className="menu-item-template">
                <div className="hotel-selector">
                  <Icon
                    name="building"
                    style={{
                      position: "relative",
                      left: "20px",
                    }}
                  ></Icon>
                  <select
                    id="hotel-select"
                    value={selectedHotel}
                    onChange={handleHotelChange}
                    style={{
                      padding: "10px",
                      width: "200px",
                      borderRadius: "8px",
                    }}
                  >
                    {Array.isArray(hotels) && hotels.length > 0 ? (
                      hotels.map((hotel) => (
                        <option key={hotel.id} value={hotel.id}>
                          {hotel.name} ({hotel.id})
                        </option>
                      ))
                    ) : (
                      <option value="">No hotels available</option>
                    )}
                  </select>
                </div>
              </div> */}
              {/* <div
                style={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={handleLogout}
              >
                <Icon name="signout" className="text-2xl font-bold"></Icon>
              </div> */}
              {/* </QuickNav> */}
            </div>
          </div>
        </div>
      </div>

      {/* <Offcanvas
        className="offcanvas-size-lg"
        placement="end"
        show={showOffcanvas}
        onHide={handleOffcanvasClose}
      >
        <Offcanvas.Header closeButton className="border-bottom border-light">
          <Offcanvas.Title>Recent Notification</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SimpleBar>
            <Schedule>
              <Schedule.Item symbol="active">
                <span className="smaller">2:12 PM</span>
                <div className="h6">Added 3 New Images</div>
                <ul className="d-flex flex-wrap gap g-2 pt-2">
                  <li>
                    <Media size="xxl">
                      <Image
                        src="/images/product/a.jpg"
                        alt="gallery"
                        thumbnail
                      />
                    </Media>
                  </li>
                  <li>
                    <Media size="xxl">
                      <Image
                        src="/images/product/b.jpg"
                        alt="gallery"
                        thumbnail
                      />
                    </Media>
                  </li>
                  <li>
                    <Media size="xxl">
                      <Image
                        src="/images/product/c.jpg"
                        alt="gallery"
                        thumbnail
                      />
                    </Media>
                  </li>
                </ul>
              </Schedule.Item>
              <Schedule.Item symbol="active">
                <span className="smaller">4:23 PM</span>
                <div className="h6">
                  Invitation for creative designs pattern
                </div>
              </Schedule.Item>
              <Schedule.Item
                symbol="active"
                contentClass="nk-schedule-content-no-border"
              >
                <span className="smaller">10:30 PM</span>
                <div className="h6">Task report - uploaded weekly reports</div>
                <div className="list-group-dotted mt-3">
                  <div className="list-group-wrap">
                    <div className="p-3">
                      <MediaGroup>
                        <Media className="rounded-0">
                          <Image
                            src="/images/icon/file-type-pdf.svg"
                            alt="icon"
                          />
                        </Media>
                        <MediaText className="ms-1">
                          <a href="#download" className="title">
                            Modern Designs Pattern
                          </a>
                          <span className="text smaller">1.6.mb</span>
                        </MediaText>
                      </MediaGroup>
                    </div>
                    <div className="p-3">
                      <MediaGroup>
                        <Media className="rounded-0">
                          <Image
                            src="/images/icon/file-type-doc.svg"
                            alt="icon"
                          />
                        </Media>
                        <MediaText className="ms-1">
                          <a href="#download" className="title">
                            Cpanel Upload Guidelines
                          </a>
                          <span className="text smaller">18kb</span>
                        </MediaText>
                      </MediaGroup>
                    </div>
                    <div className="p-3">
                      <MediaGroup>
                        <Media className="rounded-0">
                          <Image
                            src="/images/icon/file-type-code.svg"
                            alt="icon"
                          />
                        </Media>
                        <MediaText className="ms-1">
                          <a href="#download" className="title">
                            Weekly Finance Reports
                          </a>
                          <span className="text smaller">10mb</span>
                        </MediaText>
                      </MediaGroup>
                    </div>
                  </div>
                </div>
              </Schedule.Item>
              <Schedule.Item symbol="active">
                <span className="smaller">3:23 PM</span>
                <div className="h6">
                  Assigned you to new database design project
                </div>
              </Schedule.Item>
              <Schedule.Item
                symbol="active"
                contentClass="nk-schedule-content-no-border flex-grow-1"
              >
                <span className="smaller">5:05 PM</span>
                <div className="h6">You have received a new order</div>
                <Alert variant="info" className="mt-2">
                  <div className="d-flex">
                    <Icon
                      size="lg"
                      name="file-code"
                      className="opacity-75"
                    ></Icon>
                    <div className="ms-2 d-flex flex-wrap flex-grow-1 justify-content-between">
                      <div>
                        <h6 className="alert-heading mb-0">
                          Business Template - UI/UX design
                        </h6>
                        <span className="smaller">
                          Shared information with your team to understand and
                          contribute to your project.
                        </span>
                      </div>
                      <div className="d-block mt-1">
                        <Button size="md" variant="info">
                          <Icon name="download"></Icon>
                          <span>Download</span>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Alert>
              </Schedule.Item>
              <Schedule.Item symbol="active">
                <span className="smaller">2:45 PM</span>
                <div className="h6">Project status updated successfully</div>
              </Schedule.Item>
            </Schedule>
          </SimpleBar>
        </Offcanvas.Body>
      </Offcanvas> */}
    </>
  );
}

export default Header;
